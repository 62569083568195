:root {
  --font-body: 'Vollkorn', 'Georgia', serif;
  --font-italic: 'Crimson Text', 'Georgia', serif;
  --font-sans: 'Montserrat', sans-serif;
  --font-monospace: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
  --font-inter: 'Inter', sans-serif;
  --base-font-size: 100;
  --line-height: 1.625;
  --layout-max-width: 45em;
  --color-dark: #162c35;
  --color-bright: #fff;
  --color-grey: #966e6e;
  --color-bubblegum: #db7f6f;
  --color-neon: hsl(317 100% 54%);
  --base: calc(16px * (var(--base-font-size) / 100));
  --leading: calc(var(--base) * var(--line-height));
}

@media screen and (min-width: 50em) {
  :root {
    --base-font-size: 112.5;
    --line-height: 1.7;
  }

  body,
  html {
    margin: 0;
    width: 100%;
    max-width: none;
  }
}

body {
  background: var(--color-dark);
  margin: 0;
  overflow: hidden;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: #2c2c2c;
}

::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border: 3px solid #2c2c2c;
  border-radius: 7px;
}

html {
  box-sizing: border-box;
  font-family: var(--font-body);
  font-size: var(--base);
  color: var(--color-bright);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*::selection {
  background: var(--color-bubblegum);
  color: var(--color-bright);
  -webkit-text-fill-color: var(--color-bright);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-sans);
  font-weight: lighter;
}

h1 {
  font-size: calc(2.5 * var(--base));
  line-height: calc(2 * var(--leading));
  margin-top: calc(4 * var(--leading));
  margin-bottom: calc(1 * var(--leading));
}

h2 {
  font-size: calc(1.6875 * var(--base));
  line-height: calc(1.5 * var(--leading));
  margin-top: calc(2.5 * var(--leading));
  margin-bottom: calc(0.5 * var(--leading));
}

h3 {
  font-size: calc(1.375 * var(--base));
  line-height: calc(1 * var(--leading));
  margin-top: calc(2 * var(--leading));
  margin-bottom: calc(0.5 * var(--leading));
}

h4 {
  font-size: calc(1.2 * var(--base));
  line-height: calc(1 * var(--leading));
  margin-top: calc(1.5 * var(--leading));
  margin-bottom: calc(0.5 * var(--leading));
}

h5 {
  font-size: calc(1 * var(--base));
  line-height: calc(1 * var(--leading));
  margin-top: calc(2.5 * var(--leading));
  margin-bottom: calc(0.5 * var(--leading));
}

h6 {
  font-size: calc(1 * var(--base));
  line-height: calc(1 * var(--leading));
  margin-top: calc(2.5 * var(--leading));
  margin-bottom: calc(0.5 * var(--leading));
}

h1 + h2 {
  margin-top: var(--leading);
}

h2 + h3,
h3 + h4,
h4 + h5 {
  margin-top: calc(.5 * var(--leading));
}

h6 {
  font-style: italic;
  font-weight: normal;
}

h5 + h6 {
  margin-top: calc(-.5 * var(--leading));
}

p {
  line-height: var(--leading);
  margin-bottom: var(--leading);
}

p + p {
  text-indent: var(--leading);
}

a {
  color: var(--color-bubblegum);
}

i {
  font-family: var(--font-italic);
  font-style: italic;
}

sup {
  font-family: var(--font-italic);
}

q:lang(hu) {
  quotes: '„' '“';
}

.summary {
  font-size: calc(1.333 * var(--base));
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

:not(pre) > code,
kbd,
samp {
  font-family: var(--font-monospace);
  color: var(--color-bubblegum);
  background: #333;
  text-shadow: 0 1px 1px var(--color-dark);
  border-radius: 4px;
  white-space: nowrap;
  font-size: calc(.75em + .1vw);
  line-height: calc(1.5em + .2vw) !important;
  padding: 0 .2725em;
  display: inline-block;
  text-indent: 0;
}


pre[class*='language-'] {
  font-family: var(--font-monospace);
  font-size: calc(.75em + .1vw);
  display: block;
  margin-bottom: var(--leading) !important;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
  overflow: auto;
  max-width: 100vw;
  border: 1px solid #4a4a4a;
  border-radius: 8px;
  min-width: 250px;
  padding: var(--base) !important;
}

pre[class*='language-'] > code {
  display: inline-table;
  white-space: pre;
}

.footnotes ol {
  font-size: calc(0.825 * var(--base));
}

.footnotes hr {
  border: 0;
  border-bottom: 1px solid var(--color-grey);
}
